@use "_theming.module.scss" as theming;
@use "_theming.hicounselor.module.scss" as hicounselor;

.MuiChip-root {
  background-color: #fff !important;
}

.MuiInputBase-input {
  color: initial !important;
}

@media screen and (max-width: 600px) {
  .prev-next-btn {
    width: 100%;
    &:last-child {
      margin-bottom: 100px;
    }
  }
}
.date-field {
  .MuiButtonBase-root {
    display: none;
  }
}

.MuiDataGrid-cell[data-field="url"] {
  cursor: pointer;
}

.MuiAutocomplete-popper {
  > .MuiAutocomplete-paper {
    box-shadow: 2px 6px 11px 0px #00000038 !important;
  }
}

.my-assignments-datagrid {
  .MuiDataGrid-row {
    cursor: pointer;
  }
}
mark {
  background-color: #ececec;
  color: inherit;
}

.jobstep-theme {
  .MuiChip-root {
    color: theming.$primary !important;
    border: 2px solid theming.$primary !important;
  }

  .MuiChip-deleteIcon:hover {
    color: theming.$primary !important;
  }

  .MuiAlert-filledSuccess {
    background-color: theming.$success !important;
  }

  .MuiAlert-filledError {
    background-color: theming.$error !important;
  }

  .MuiAccordion-root {
    border-radius: 0 !important;
    border-bottom: 1px solid map-get($map: theming.$brandPalette, $key: P300) !important;
  }
}

.hicounselor-theme {
  .MuiChip-root {
    color: hicounselor.$primary !important;
    border: 2px solid hicounselor.$primary !important;
  }

  .MuiChip-deleteIcon:hover {
    color: hicounselor.$primary !important;
  }

  .MuiAlert-filledSuccess {
    background-color: hicounselor.$success !important;
  }

  .MuiAlert-filledError {
    background-color: hicounselor.$error !important;
  }

  .MuiAccordion-root {
    border-radius: 0 !important;
    border-bottom: 1px solid
      map-get($map: hicounselor.$brandPalette, $key: P300) !important;
  }
}

.MuiAccordion-root:before {
  content: unset !important;
}

.MuiAccordionDetails-root {
  display: block !important;
}

.MuiAccordionDetails-root {
  .MuiTypography-root:first-child {
    > div:first-child {
      .h5 {
        margin-top: 0 !important;
      }
    }
  }
}

.MuiPaper-elevation1 {
  box-shadow: none !important;
}
