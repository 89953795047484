@media (min-width: 600px) {
  .MuiToolbar-regular {
    min-height: 88px !important;
  }
}

.MuiToolbar-root {
  color: #333 !important;
}

.appbar-logo {
  height: 88px;
  background-position: left center;
  width: 66%;
  background-repeat: no-repeat;
  background-size: contain;
}

.jobstep-theme .appbar-logo {
  background-image: url("./jobstep-logo.svg");
}

.hicounselor-theme .appbar-logo {
  height: 74px !important;
  background-image: url("./logo.webp");
}
