@use "_theming.module.scss" as theming;
@use "_theming.hicounselor.module.scss" as hicounselor;

.my-drawer {
  .MuiPaper-root {
    z-index: 0 !important;
    border: none !important;
    background: transparent;
  }
  .nav-menu-fragment {
    background: #fff;
    margin-bottom: 32px;
    border-radius: 4px;
    .MuiListItem-root {
      padding-top: 0;
      padding-bottom: 0;
      height: 48px;

      &:first-child {
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
      }

      &:last-child {
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
      }
    }
  }

  .MuiCollapse-container .MuiListItem-root {
    padding-top: 0;
    padding-bottom: 0;
    height: 36px;
  }
  .MuiListItemIcon-root {
    min-width: 42px !important;
  }

  .MuiTypography-body1 {
    font-size: 15px !important;
  }
}

.jobstep-theme .my-drawer {
  .MuiTypography-body1 {
    color: map-get($map: theming.$brandPalette, $key: P800) !important;
  }

  .MuiListItem-root.Mui-selected,
  .MuiListItem-root.Mui-selected:hover {
    background-color: map-get($map: theming.$brandPalette, $key: P100);
    border-left: 2px solid map-get($map: theming.$brandPalette, $key: P800);
  }

  svg {
    color: map-get($map: theming.$brandPalette, $key: P800);
  }
}

.hicounselor-theme .my-drawer {
  .MuiTypography-body1 {
    color: map-get($map: hicounselor.$brandPalette, $key: P800) !important;
  }

  .MuiListItem-root.Mui-selected,
  .MuiListItem-root.Mui-selected:hover {
    background-color: map-get($map: hicounselor.$brandPalette, $key: P100);
    border-left: 2px solid map-get($map: hicounselor.$brandPalette, $key: P800);
  }

  svg {
    color: map-get($map: hicounselor.$brandPalette, $key: P800);
  }
}
